<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header
        title="프리미엄 콘텐츠 판매"
        description="세상 모든 콘텐츠를 한 곳에서 판매하세요. VOD, 라이브, 1:1 코칭, 팟캐스트 모두 가능합니다."
        guide-link="https://bigc-im.notion.site/5f6c6ab0ed534a6a9b2bdaafd7d97f48"
        :border-bottom="false"
      ></page-header>

      <div class="container-body">
        <div class="contents-section">
          <!--  mobile, tablet  -->
          <ul>
            <li
              v-for="content in state.premiumContents"
              :key="`content-${content.resourceId}`"
            >
              <content-item
                :content="content"
                @click="openGuideModal()"
              ></content-item>
            </li>
          </ul>

          <!-- pc -->
          <div class="content-list__pc">
            <button
              v-if="state.premiumContents.length > 6"
              class="swiper-button-prev"
              :disabled="!state.activePremiumContentSwiperPrevBtn"
              @click="actions.prevSwiperPremiumContent()"
            >
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <button
              v-if="state.premiumContents.length > 6"
              class="swiper-button-next"
              :disabled="!state.activePremiumContentSwiperNextBtn"
              @click="actions.nextSwiperPremiumContent()"
            >
              <i class="fa-solid fa-chevron-right"></i>
            </button>

            <swiper
              :slides-per-view="4"
              :space-between="24"
              :pagination="{
                type: 'fraction',
              }"
              :navigation="
                state.premiumContents.length > 6
                  ? {
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }
                  : false
              "
              :breakpoints="{
                1024: {
                  slidesPerView: 6,
                },
              }"
              @swiper="onPremiumContentSwiper"
              @slideChange="actions.onSlideChangePremiumContent()"
            >
              <swiper-slide
                v-for="theme in state.premiumContents"
                :key="`content-${theme.resourceId}`"
              >
                <content-item
                  :content="theme"
                  @click="actions.createThemeClub(theme)"
                ></content-item>
              </swiper-slide>
            </swiper>
          </div>
        </div>

        <div class="banner-section">
          <h2>고민없이 시작하는 테마 라이브</h2>
          <p class="text-gray-second sub-text-s2">
            30초만에 만드는 세상에서 가장 편한 유료 라이브. 빅크가 분석한 잘
            팔리는 콘텐츠를 아낌없이 공유드립니다.
          </p>
        </div>

        <div class="recommend-section">
          <h4>{{ state.userName }}님에게 추천드리는 테마</h4>
          <ul class="recommend-list">
            <li
              v-for="recommendTheme in state.recommendThemeLives"
              :key="`recommendTheme-${recommendTheme.resourceId} `"
            >
              <recommend-theme-item
                :theme-club="recommendTheme"
                @click="actions.prevThemeClub(recommendTheme)"
              ></recommend-theme-item>
            </li>
          </ul>
        </div>

        <div class="meetings-section">
          <div
            v-for="themeLive in state.themeLives"
            :key="`theme-group-${themeLive.resourceId}`"
            class="meeting-group"
          >
            <h4>{{ themeLive.title }}</h4>

            <!--  mobile, tablet  -->
            <ul>
              <li
                v-for="themeClub in themeLive.themeClubs"
                :key="`content-${themeClub.resourceId}`"
              >
                <theme-live-item
                  :theme-club="themeClub"
                  @click="openGuideModal()"
                ></theme-live-item>
              </li>
            </ul>

            <!-- pc -->
            <div class="pc">
              <theme-live-group
                :theme-live="themeLive"
                @prevThemeClub="(theme) => actions.prevThemeClub(theme)"
              ></theme-live-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <theme-prev-modal
      v-if="state.showPrevThemeLive && false"
      :theme-club="state.selectedTheme"
      @hideModal="actions.closePrevThemeLive()"
      @createThemeClub="(themeClub) => actions.createThemeClub(themeClub)"
    ></theme-prev-modal>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showGuideModal"
        warning-title="프리미엄 콘텐츠는 PC에서만 설정 가능합니다."
        warning-text="설정을 원하시면 PC로 접속 부탁드립니다."
        confirm-text="확인"
        :show-cancel-button="false"
        @confirm="closeGuideModal()"
        @hideModal="closeGuideModal()"
      ></warning-modal>
      <ent-club-create-modal
        v-if="state.showEntClubCreateModal"
        :club-theme="state.selectedTheme"
        @hide-modal="actions.closeEntClubCreateModal()"
        @save-complete="(value) => actions.createEntClub(value)"
      ></ent-club-create-modal>
    </teleport>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import ThemeLiveItem from "./ThemeLiveItem/ThemeLiveItem";
import { useStore } from "vuex";
import { computed, getCurrentInstance, onBeforeMount, reactive } from "vue";
import { useRouter } from "vue-router";
import ApiService from "@/api";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ThemePrevModal from "./ThemePrevModal/ThemePrevModal";
import ContentItem from "./ContentItem/ContentItem";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import RecommendThemeItem from "./RecommendThemeItem/RecommadThemeItem";
import helper from "@/helper";
import ThemeLiveGroup from "./ThemeLiveGroup/ThemeLiveGroup";
import WarningModal from "../../../components/console/modals/WarningModal";
import "swiper/swiper-bundle.css";
import swal from "@/helper/swal";
import EntClubCreateModal from "./EntClubCreateModal/EntClubCreateModal.vue";

export default {
  name: "ClubCreate",
  components: {
    EntClubCreateModal,
    WarningModal,
    ThemeLiveGroup,
    RecommendThemeItem,
    ContentItem,
    ThemePrevModal,
    PageLoading,
    PageHeader,
    ThemeLiveItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      pageLoading: false,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      userName: computed(() => {
        return helper.displayUserName(state.user);
      }),
      themeLives: [],
      recommendThemeLives: [],
      premiumContents: [],
      clubCreating: false,
      showPrevThemeLive: false,
      selectedTheme: null,
      premiumContentSwiper: null,
      activePremiumContentSwiperNextBtn: true,
      activePremiumContentSwiperPrevBtn: false,
      showGuideModal: false,
      showEntClubCreateModal: false,
    });

    onBeforeMount(() => {
      state.pageLoading = true;

      ApiService.getClubAllThemes().then((res) => {
        if (res.data.success) {
          const premiumContents = res.data.data.premiumContents;
          state.premiumContents = premiumContents.filter((item) => {
            return item.type !== 92 && item.type !== 93;
          });

          // FIXME ANDREW 임시 로 4개까지만 나오도록 수정
          state.recommendThemeLives = res.data.data.recommendThemeLive.slice(
            0,
            4
          );

          state.themeLives = res.data.data.themeLive;
          state.pageLoading = false;
        }
      });
    });

    const onPremiumContentSwiper = (swiper) => {
      state.premiumContentSwiper = swiper;
    };

    const openGuideModal = () => {
      state.showGuideModal = true;
    };

    const closeGuideModal = () => {
      state.showGuideModal = false;
    };

    const actions = {
      createEntClub: (clubForm) => {
        state.clubCreating = true;
        clubForm.themeClubResourceId = state.selectedTheme.resourceId;
        store
          .dispatch("newClubs/postClub", {
            data: clubForm,
          })
          .then(() => {
            state.clubCreating = false;
            swal.successToast("생성되었습니다.");
            router.push({
              name: "console.clubs.show",
              params: {
                clubResourceId: store.getters["newClubs/newClub"].resourceId,
              },
            });
            state.showEntClubCreateModal = false;
            // //note 클럽 생성 후 클럽의 isTemporary 값에 따라 보여지는 화면 조정
            // state.filters.isTemporary = clubForm.isTemporary;
            // getClubs(state.filters);
          });
      },
      createThemeClub: (themeClub) => {
        if (
          themeClub.status !== proxy.$const.CLUB_LOCKED_THEME &&
          !state.clubCreating
        ) {
          state.showEntClubCreateModal = true;
          state.selectedTheme = themeClub;
          // store
          //   .dispatch("newClubs/postThemeClub", {
          //     data: {
          //       themeClubResourceId: themeClub.resourceId,
          //     },
          //   })
          //   .then(() => {
          //     state.showPrevThemeLive = false;
          //     let createdClub = store.getters["newClubs/newClub"];
          //     router.push({
          //       name: "console.clubs.edit",
          //       params: {
          //         clubResourceId: createdClub.resourceId,
          //       },
          //     });
          //     state.clubCreating = false;
          //   });
        }
      },
      prevThemeClub: (theme) => {
        if (window.innerWidth < 992) {
          openGuideModal();
          return;
        }

        // if (
        //   state.selectedTheme &&
        //   theme.resourceId === state.selectedTheme.resourceId
        // ) {
        //   return;
        // }
        // if (
        //   theme?.status !== proxy.$const.CLUB_LOCKED_THEME &&
        //   !state.clubCreating
        // ) {
        //   state.showEntClubCreateModal = true;
        //   state.selectedTheme = theme;
        // }
        if (theme?.status !== proxy.$const.CLUB_LOCKED_THEME) {
          state.showEntClubCreateModal = true;
          state.selectedTheme = theme;
        }
        // if (state.showPrevThemeLive) {
        //   state.showPrevThemeLive = false;
        //   setTimeout(() => {
        //     state.showPrevThemeLive = true;
        //   }, 200);
        // } else {
        //   state.showPrevThemeLive = true;
        // }
      },
      closePrevThemeLive: () => {
        state.showPrevThemeLive = false;
        state.selectedTheme = null;
      },
      prevSwiperPremiumContent: () => {
        state.premiumContentSwiper.slidePrev();
      },
      nextSwiperPremiumContent: () => {
        state.premiumContentSwiper.slideNext();
      },
      onSlideChangePremiumContent: () => {
        state.activePremiumContentSwiperPrevBtn = true;
        state.activePremiumContentSwiperNextBtn = true;
        if (state.premiumContentSwiper.activeIndex === 0) {
          state.activePremiumContentSwiperPrevBtn = false;
          return;
        }
        if (state.premiumContentSwiper.isEnd) {
          state.activePremiumContentSwiperNextBtn = false;
          return;
        }
      },
      openEntClubCreateModal: () => {
        state.showEntClubCreateModal = true;
      },
      closeEntClubCreateModal: () => {
        state.selectedTheme = null;
        state.showEntClubCreateModal = false;
      },
    };

    return {
      state,
      actions,
      onPremiumContentSwiper,
      openGuideModal,
      closeGuideModal,
    };
  },
};
</script>

<style scoped src="./style.css"></style>

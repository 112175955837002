<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>클럽 생성</h3>
      </div>
    </template>

    <template #modalBody>
      <!--      테마 상태 공개여부,공개일 설정, 썸네일, 제목, 헤드라인, 내용-->
      <div class="info-form">
        <!--startAt, finishAt-->
        <div class="input-item">
          <label class="sub-text-s2 label"
            >시작일<span class="text-purple-50">*</span></label
          >
          <input-date-picker
            class="input-item"
            label="시작일"
            :date="state.clubForm.startAt"
            format="YYYY-MM-DD HH:mm"
            @updateDate="(value) => actions.updateClubDate('startAt', value)"
          ></input-date-picker>
        </div>
        <div class="input-item">
          <label class="sub-text-s2 label"
            >종료일<span class="text-purple-50">*</span></label
          >
          <input-date-picker
            class="input-item"
            label="종료일"
            :date="state.clubForm.finishAt"
            format="YYYY-MM-DD HH:mm"
            @updateDate="(value) => actions.updateClubDate('finishAt', value)"
          ></input-date-picker>
        </div>
        <div class="input-select-form">
          <input-switch-new
            v-model="state.clubForm.isOpen"
            label="클럽 공개 여부"
            placement="left"
            text-size="s2"
          ></input-switch-new>
        </div>

        <div class="club-thumbnail">
          <label class="sub-text-s2" for="featured-image">메인 이미지</label>
          <div
            id="featured-image"
            class="featured-img"
            :class="{ empty: !state.prevFeaturedImage }"
            :style="`background-image: url(${state.prevFeaturedImage});`"
            @click="actions.openFileStack('pc')"
          >
            <div class="img-upload">
              <camera-button></camera-button>
            </div>
          </div>
        </div>

        <input-basic-new
          class="input-item"
          label="제목"
          :required="true"
          :default-value="state.clubForm.title"
          @updateData="(value) => actions.updateClubData('title', value)"
        ></input-basic-new>
        <input-basic-new
          class="input-item"
          label="헤드라인"
          :default-value="state.clubForm.headline"
          @updateData="(value) => actions.updateClubData('headline', value)"
        ></input-basic-new>

        <quill-editor
          class="input-item"
          editor-id="club-description"
          label="내용"
          placeholder=""
          :value="state.clubForm.description"
          editor-container-height="600"
          @updateValue="(value) => actions.updateClubData('description', value)"
        >
        </quill-editor>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="sub-title-s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          text="생성"
          text-size="sub-title-s1"
          :disabled="!state.isActiveSaveBtn"
          @action="actions.createEntClub()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, getCurrentInstance, onMounted } from "vue";
import moment from "moment-timezone";
import InputDatePicker from "../../../../components/console/inputs/InputDatePicker";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "../../../../components/console/inputs/InputBasicNew";
import FileStackService from "@/services/FileStackService";
import CameraButton from "../CameraButton/CameraButton";
// import InputSwitchNew from "@/components/console/inputs/InputSwitchNew.vue";
import helper from "@/helper";
import { useStore } from "vuex";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import ApiService from "@/api";
import InputSwitchNew from "@/components/console/inputs/InputSwitchNew.vue";

export default {
  name: "EntClubCreateModal",
  components: {
    InputSwitchNew,
    QuillEditor,
    // InputSwitchNew,
    ButtonBasic,
    BaseModalSide,
    InputBasicNew,
    InputDatePicker,
    CameraButton,
  },
  props: {
    clubTheme: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      prevFeaturedImage: computed(() => {
        return state.clubForm.featuredImage;
      }),
      clubForm: {
        title: "",
        headline: "",
        description: "",
        featuredImage: proxy.$const.blankImage,
        introHost: "",
        introTicket: "",
        introSession: "",
        //todo 날짜 null 바꾸기
        startAt: "",
        finishAt: "",
        isTemporary: false,
        themeClubResourceId: null,
      },
      errorMessage: "",
      isActiveSaveBtn: computed(() => {
        return state.clubForm.title.length > 0;
      }),
    });

    onMounted(() => {
      let clubTheme = props.clubTheme;
      if (clubTheme?.resourceId) {
        state.clubForm.title = clubTheme.holderTitle
          ? `${clubTheme.holderTitle.replaceAll(
              "{{name}}",
              helper.displayUserName(state.user)
            )}`
          : clubTheme.holderTitle;
        state.clubForm.featuredImage = clubTheme.featuredImage
          ? clubTheme.featuredImage
          : proxy.$const.blankImage;
        state.clubForm.introTicket = `${helper.displayUserName(state.user)}의 ${
          clubTheme.title
        } 입장권`;
        state.clubForm.introSession = clubTheme.title
          ? `${helper.displayUserName(state.user)}의 ${
              clubTheme.title
            } LIVE 참여`
          : "";
        state.clubForm.description = clubTheme.holderDescription
          ? helper.nl2br(
              clubTheme.holderDescription.replaceAll(
                "{{name}}",
                helper.displayUserName(state.user)
              )
            )
          : "";
      }
    });

    const postAttachmentImageUrl = async (stateData, imageUrl) => {
      const formData = new FormData();
      formData.append("file", imageUrl);
      await ApiService.postAttachment(formData).then((res) => {
        if (res.data.success) {
          state.clubForm[stateData] = res.data.data.url;
        }
      });
    };

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateClubData: (key, value) => {
        state.clubForm[key] = value;
      },
      updateClubDate: (key, value) => {
        state.clubForm[key] = moment(value);
      },
      createEntClub: () => {
        emit("saveComplete", state.clubForm);
      },
      openFileStack: (type) => {
        let filestack = new FileStackService();
        filestack.options.transformations.crop.aspectRatio = 1 / 1;
        filestack.options.onFileUploadFinished = async (fileMetaData) => {
          if (type === "pc") {
            await postAttachmentImageUrl("featuredImage", fileMetaData.url);
          }
        };

        filestack.open(filestack.options);
      },
    };

    return { state, actions, moment };
  },
};
</script>

<style src="./style.css" scoped></style>
